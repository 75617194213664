import React from "react";
import Team from "./Block/teams";
import Sdata from './Block/sdata'

function OurTeam(){
    return(
        <>
        {/* our team */}
            <section className="light_gray_bg  small_pb">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="heading_s3 text-center animation" data-animation="fadeInUp" data-animation-delay="0.2s">
                            <h2>Website Application From SCRATCH!</h2>
                            <p>Beyond Development, our simple process involves following steps</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="cleafix small_divider"></div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                            {Sdata.map((val) => {
                                return(
                                    <Team 
                                        key= {val.id}
                                        title={val.title}
                                        para={val.para}
                                        icon={val.icon}
                                    />
                                );
                            })}
                    </div>
                </div>
            </section>
        </>
    );
}

export default OurTeam;
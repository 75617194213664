import React from "react";


function Services(props){
    return(
        <>
        <div className="col-md-4 col-sm-6 text-center">
            <div className="icon_box icon_box_style_8 box_shadow3 radius_box_10 bg-white animation" data-animation="fadeInUp" data-animation-delay="0.3s">
                <div className="box_icon mb-3">	
                    <img src={props.icon} />
                </div>
                <div className="icon_box_content">
                    <h5>{props.title}</h5>
                    <p>{props.para}</p>
                </div>
            </div>
        </div>
        </>
    )
}

export default Services;
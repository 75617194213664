import React from "react";


function Video(){
    return(
        <>
        {/* Our Client */}
        <section className="small_pb">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="heading_s3 text-center animation" data-animation="fadeInUp" data-animation-delay="0.2s">
                                <h2>Our weekly Top Pick's</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row blog_wrap justify-content-center">
                        <div className="col-lg-6 col-md-6 mb-md-4 mb-2 pb-2">
                            <div className="blog_post blog_style1 radius_box_10 animation" data-animation="fadeInUp" data-animation-delay="0.3s">
                                <div className="blog_img">
                                <iframe src="https://www.youtube.com/embed/88THDsuvJr0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                                <div className="blog_content bg-white">
                                    <div className="blog_text">
                                        <h6 className="blog_title"><a href="#">Reasons For Large '"LAY OFF" | How To Come Out Of Recession</a></h6>
                                        {/* <ul className="list_none blog_meta">
                                            <li><a href="#"><i className="ion-calendar"></i> April 14, 2018</a></li>
                                            <li><a href="#"><i className="ion-chatboxes"></i> 2 Comment</a></li>
                                        </ul>
                                        <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text</p>
                                        <a href="#" className="text-capitalize">Read More</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 mb-md-4 mb-2 pb-2">
                            <div className="blog_post blog_style1 radius_box_10 animation" data-animation="fadeInUp" data-animation-delay="0.4s">
                                <div className="blog_img">
                                <iframe width="510" height="288" src="https://www.youtube.com/embed/BRn9j4TL1vI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                                <div className="blog_content bg-white">
                                    <div className="blog_text">
                                        <h6 className="blog_title"><a href="#">What is an algorithm ?</a></h6>
                                        {/* <ul className="list_none blog_meta">
                                            <li><a href="#"><i className="ion-calendar"></i> April 14, 2018</a></li>
                                            <li><a href="#"><i className="ion-chatboxes"></i> 2 Comment</a></li>
                                        </ul>
                                        <p>Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                                        <a href="#" className="text-capitalize">Read More</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-6 mb-md-4 mb-2 pb-2">
                            <div className="blog_post blog_style1 radius_box_10 animation" data-animation="fadeInUp" data-animation-delay="0.5s">
                                <div className="blog_img">
                                    <a href="#">
                                        <img src="demo-digital-agency/images/blog_small_img3.jpg" alt="blog_small_img3" />
                                    </a>
                                </div>
                                <div className="blog_content bg-white">
                                    <div className="blog_text">
                                        <h6 className="blog_title"><a href="#">But I must explain to you how all this mistaken idea of denouncing</a></h6>
                                        <ul className="list_none blog_meta">
                                            <li><a href="#"><i className="ion-calendar"></i> April 14, 2018</a></li>
                                            <li><a href="#"><i className="ion-chatboxes"></i> 2 Comment</a></li>
                                        </ul>
                                        <p>It uses a dictionary of over combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable</p>
                                        <a href="#" className="text-capitalize">Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Video;
// array of data

const Sdata = [
    {
        id: 1,
        icon: "assets/images/step1.png",
        title: "Brainstorming",
        para: "Step 1",
    },
    {
        id: 2,
        icon: "assets/images/step2.png",
        title: "Product Placement",
        para: "Step 2",
    },
    {
        id: 3,
        icon: "assets/images/step3.png",
        title: "Digital Branding",
        para: "Step 3",
    },
    {
        id: 4,
        icon: "assets/images/step4.png",
        title: "Analytics Tracking",
        para: "Step 4",
    }
]


export default Sdata;
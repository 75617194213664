import React from "react";
import Services from "./Block/services"
import Sdata from "./Block/sdata"


function OurServices(){
    return(
        <section name="services" className="parallax_bg" data-parallax-speed="0.5" data-parallax-bg-image="demo-digital-agency/images/shape_img1.png">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-9">
                        <div className="heading_s3 text-center animation" data-animation="fadeInUp" data-animation-delay="0.2s">
                            <h2>Experience is everything</h2>
                        </div>
                        <p className="text-center animation" data-animation="fadeInUp" data-animation-delay="0.2s">we know what you expect! <br/><strong>we support most of the mainstream listed below</strong> </p>
                        {/* <p className="text-center animation" data-animation="fadeInUp" data-animation-delay="0.2s">We offer EXCLUSIVELY FOR BRANDS</p> */}

                  
                    </div>
                </div>
                <div className="row">
                    
                    {Sdata.map((val) => {
                        return(
                            <Services 
                                key={val.id}
                                icon={val.icon}
                                title={val.title}
                                para={val.para}
                            />
                        );
                    })}

                </div>
            </div>
        </section>
    );
}


export default OurServices;
import React from "react";


function GetForm(){
    return(

        <>
        <section name="contact" className="small_pt parallax_bg" data-parallax-speed="0.5" data-parallax-bg-image="demo-digital-agency/images/shape_img2.png">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-8 text-center">
                        <div className="heading_s3 mb-md-3 text-center animation" data-animation="fadeInUp" data-animation-delay="0.2s">
                            <h2>Get In touch</h2>
                        </div>
                        <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus blandit massa enim. Nullam id varius nunc id varius nunc.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="clearfix small_divider"></div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-12">
                        <div className="field_form form_style3 animation" data-animation="fadeInUp" data-animation-delay="0.4s">
                            <form method="post" name="enq">
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <input required="required" placeholder="Enter Name *" id="first-name" className="form-control" name="name" type="text" />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <input required="required" placeholder="Enter Email *" id="email" className="form-control" name="email" type="email" />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <input placeholder="Enter Subject" id="subject" className="form-control" name="subject" type="subject" />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <textarea required="required" placeholder="Message *" id="description" className="form-control" name="message" rows="4"></textarea>
                                    </div>
                                    <div className="col-md-12 text-center">
                                        <button type="submit" title="Submit Your Message!" className="btn btn-outline-default btn-radius" id="submitButton" name="submit" value="Submit">Submit</button>
                                    </div>
                                    <div className="col-lg-12">
                                        <div id="alert-msg" className="alert-msg text-center"></div>
                                    </div>
                                </div>
                            </form>		
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    )
}

export default GetForm;
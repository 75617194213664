import React from "react";
import Loader from './component/home/Loader';
import Header from './Layouts/Header/Header';
import Banner from './component/home/Banner';
import OurServices from './component/home/Services/OurServices';
import LightGray from './component/home/LightGray';
import Business from './component/home/Business/Business';
import OurTeam from './component/home/Team/OurTeam';
import OurClient from './component/home/OurClient';
import News from './component/home/News';
import Video from './component/home/Video';
import GetForm from './component/home/GetForm';
import Footer from './Layouts/Footer/Footer';


const Home = () =>{
    return(
        <>
        <Loader />
        <Header />
        <Banner />
        <OurServices />
        <LightGray />
        <Business/>
        <OurTeam />
        {/* <News /> */}
        <Video />
        <OurClient />
        <GetForm />
        <Footer />
      </>
    )
}

export default Home;
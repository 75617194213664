import React from "react";


function OurClient(){
    return(
        <>
        {/* START SECTION TESTIMONIAL */}
        <section className="light_gray_bg">	
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="heading_s3 animation" data-animation="fadeInUp" data-animation-delay="0.2s">	
                            <h2>Our Client Says</h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="testimonial_slider testimonial_style3 carousel_slide3 owl-carousel owl-theme nav_top animation" data-margin="10" data-nav="true" data-dots="false" data-loop="true" data-autoplay="true" data-animation="fadeInUp" data-animation-delay="0.4s">
                            <div className="item">
                                <div className="testimonial_box box_shadow3 radius_box_10 bg-white">
                                    <div className="testi_meta">
                                        <div className="quote">
                                            <img src="assets/images/quote2.png" alt="quote2" />
                                        </div>
                                        <p>The team at Codeavoid transformed my outdated website into a modern, professional platform that has helped drive my business forward. Highly recommended!</p>
                                    </div>
                                    <div className="testimonial_cl_info">
                                        <div className="testimonial_img">
                                            <img className="rounded-circle m-auto" src="assets/images/user.png" alt="user"/>
                                        </div>
                                        <div className="client_info">
                                            <h6>Dharmendra Kumar</h6>
                                            <span>Founder - Mediabharti.com</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="testimonial_box box_shadow3 radius_box_10 bg-white">
                                    <div className="testi_meta">
                                        <div className="quote">
                                            <img src="assets/images/quote2.png" alt="quote2" />
                                        </div>
                                        <p>I was impressed by the level of expertise and creativity shown by these folks during the development of my website. I am happy with the results!</p>
                                    </div>
                                    <div className="testimonial_cl_info">
                                        <div className="testimonial_img">
                                            <img className="rounded-circle m-auto" src="assets/images/user.png" alt="user"/>
                                        </div>
                                        <div className="client_info">
                                            <h6>Prakalp Varshney</h6>
                                            <span>Technology Lead</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="testimonial_box box_shadow3 radius_box_10 bg-white">
                                    <div className="testi_meta">
                                        <div className="quote">
                                            <img src="assets/images/quote2.png" alt="quote2" />
                                        </div>
                                        <p>Working with codeavoid was a seamless experience. They took the time to understand my needs and delivered a custom website that exceeded my expectations.</p>
                                    </div>
                                    <div className="testimonial_cl_info">
                                        <div className="testimonial_img">
                                            <img className="rounded-circle m-auto" src="assets/images/user.png" alt="user"/>
                                        </div>
                                        <div className="client_info">
                                            <h6>Amelia</h6>
                                            <span>Founder - Brill Learning</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="item">
                                <div className="testimonial_box box_shadow3 radius_box_10 bg-white">
                                    <div className="testi_meta">
                                        <div className="quote">
                                            <img src="assets/images/quote2.png" alt="quote2" />
                                        </div>
                                        <p>I highly recommend [company name] for anyone looking for a web development partner. Their attention to detail and commitment to customer satisfaction is unmatched.</p>
                                    </div>
                                    <div className="testimonial_cl_info">
                                        <div className="testimonial_img">
                                            <img className="rounded-circle m-auto" src="assets/images/user4.jpg" alt="user"/>
                                        </div>
                                        <div className="client_info">
                                            <h6>Alfred Amos</h6>
                                            <span>Creative Designer</span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default OurClient;

import React from "react";
import Home from "./Home";
// import Services from "./Services";



const App = () =>{
    return(
      <>
      <Home/>
      </>
    )
}


export default App;
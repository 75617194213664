import React from "react";
import { Link } from "react-scroll";

function Banner() {
  return (
    <>
      {/* START SECTION BANNER */}
      <section
        name="home"
        id="home_section"
        className="banner_section small_pb overflow_hide"
      >
        <div className="container">
          {/* STRART CONTAINER */}
          <div className="row">
            <div className="col-12">
              <div className="large_divider clearfix"></div>
            </div>
          </div>
          <div className="row align-items-center flex-row-reverse">
            <div className="col-md-6">
              <div
                className="animation mb-4 mb-md-0"
                data-animation="fadeInRight"
                data-animation-delay="1.3s"
              >
                <img
                  src={process.env.PUBLIC_URL + "assets/images/home_banner.png"}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="banner_content bc_info animation"
                data-animation="fadeIn"
                data-animation-delay="0.8s"
              >
                <h2
                  className="animation text-uppercase"
                  data-animation="fadeInLeft"
                  data-animation-delay="1s"
                >
                  Give Your <span className="text_default">Idea's Life</span>
                </h2>
                <p
                  className="animation mt-md-4 mt-2 mb-4"
                  data-animation="fadeInLeft"
                  data-animation-delay="1.3s"
                >
                  It's the time to push into global space.
                </p>

                <Link
                  activeClass="active"
                  className="btn banner_ripple video_popup animation"
                  data-animation="fadeInLeft"
                  data-animation-delay="1.6s"
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <span className="ripple">
                    <i className="ion-play"></i>
                  </span>
                  Let's Start
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* END CONTAINER */}
        <div className="banner_shape">
          <img
            src={process.env.PUBLIC_URL + "assets/images/banner_shape.png"}
          />
        </div>
      </section>
    </>
  );
}

export default Banner;

import React from "react";


function Business() {
  return (
    <>
      <section name="about" >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-xl-6 col-md-6 col-sm-12">
              <div
                className="mb-4 mb-lg-0 animation"
                data-animation="fadeInRight"
                data-animation-delay="0.1s"
              >
                <img
                  src="assets/images/about.png"
                  alt="about us"
                />
              </div>
            </div>
            <div
              className="col-xl-5 col-md-6 col-sm-12 animation"
              data-animation="fadeInLeft"
              data-animation-delay="0.1s"
            >
              <h2 className="mb-3">
              Why Codeavoid?
              </h2>
              <p>
                At Codeavoid, we believe in creating unique and innovative web
                experiences for our clients. Our team of highly skilled web
                developers and designers work tirelessly to bring their ideas to
                life. 
                
              </p>

              <p>
               
                We understand that every business is unique and therefore,
                every website should be too.
              </p>

              <p>
               
              We take the time to
               truly understand their vision and goals to create a customized
               solution that perfectly fits their needs.
             </p>

              <p>
              We know what you need, we know we have to keep clients happy and that’s exactly how we like it!
             </p>

            

              <a href="#" className="btn btn-default btn-radius">
               One Click Consultant
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="clearfix small_divider"></div>
            </div>
          </div>
          {/* <div className="row flex-row-reverse align-items-center justify-content-between">
            <div className="col-xl-6 col-md-6 col-sm-12">
              <div
                className="mb-4 mb-lg-0 animation"
                data-animation="fadeInRight"
                data-animation-delay="0.1s"
              >
                <img
                  src="demo-digital-agency/images/vector2.png"
                  alt="vector2"
                />
              </div>
            </div>
            <div
              className="col-xl-5 col-md-6 col-sm-12 animation"
              data-animation="fadeInLeft"
              data-animation-delay="0.1s"
            >
              <h2 className="mb-3">
                Looking For vulputate Sed Nullam Etiam non pede.
              </h2>
              <p>
                If you are going to use a passage of Lorem Ipsum, you need to be
                sure there isn't anything embarrassing hidden in the middle of
                text.All the Lorem Ipsum generators on the Internet tend to
                repeat predefined chunks as necessary
              </p>
              <a href="#" className="btn btn-default btn-radius">
                Read More
              </a>
            </div>
          </div> */}
        </div>
        <div className="ripple_effect">
          <div className="circle_bg1">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="ripple_effect_left">
          <div className="circle_bg1">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </section>
    </>
  );
}

export default Business;

import React from "react";


function LightGrey(){
    return(
        <>
        <section className="counter_wrap light_gray_bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="box_counter counter_style3 counter_dark animation" data-animation="fadeInUp" data-animation-delay="0.1s">
                            <img src="demo-digital-agency/images/tea.png" />
                            <h3 className="counter_text text_default"><span className="counter">800</span>+</h3>
                            <p>Cups of Coffee</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="box_counter counter_style3 counter_dark animation" data-animation="fadeInUp" data-animation-delay="0.2s">
                            <img src="demo-digital-agency/images/team.png" />
                            <h3 className="counter_text text_default"><span className="counter">10,000+</span></h3>
                            <p>Suppot Ticket's Resolved</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="box_counter counter_style3 counter_dark animation" data-animation="fadeInUp" data-animation-delay="0.3s">
                            <img src="demo-digital-agency/images/project.png" />
                            <h3 className="counter_text text_default"><span className="counter">1200</span>+</h3>
                            <p>Projects Completed</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="box_counter counter_style3 counter_dark animation" data-animation="fadeInUp" data-animation-delay="0.4s">
                            <img src="demo-digital-agency/images/client.png" />
                            <h3 className="counter_text text_default"><span className="counter">1500</span>+</h3>
                            <p>Happy Clients</p>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
        </>
    );
}

export default LightGrey;
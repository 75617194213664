import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";

function Header() {
  return (
    <>
      <header className="header_wrap fixed-top dark_skin hover_menu_style3 main_menu_capitalize main_menu_weight_400 main_menu_size_16 transparent-header">
        <div className="top-header d-none d-md-block">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-8">
                <ul className="contact_detail list_none text-center text-md-left">
                  <li>
                    <i className="ti-email"></i>contact@codeavoid.com
                  </li>
                  {/* <li><i className="ti-mobile"></i>123-456-7890</li>
                                    <li><i className="ti-location-pin"></i>123 Street, New South London , UK</li> */}
                </ul>
              </div>
              <div className="col-md-4">
                <ul className="list_none social_icons text-center border_social rounded_social text-md-right mt-2 mt-md-0">
                  <li>
                    <a href="#">
                      <i className="ion-social-facebook"></i>
                    </a>
                  </li>
                  {/* <li><a href="#"><i className="ion-social-twitter"></i></a></li> */}
                  {/* <li><a href="#"><i className="ion-social-googleplus"></i></a></li> */}
                  <li>
                    <a href="#">
                      <i className="ion-social-youtube-outline"></i>
                    </a>
                  </li>
                  {/* <li><a href="#"><i className="ion-social-instagram-outline"></i></a></li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <a className="navbar-brand m-auto" href="index-13.html">
              <img
                className="logo_light"
                src="assets/images/shop-logo_dark.png"
                alt="logo"
              />
              <img
                className="logo_dark"
                src="assets/images/logon_final.png"
                alt="logo"
              />
              <img
                className="logo_default"
                src="assets/images/shop-logo_dark.png"
                alt="logo"
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              {" "}
              <span className="ion-android-menu"></span>{" "}
            </button>
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav">
                <li className="dropdown dropdown-mega-menu">
                <Link
                    activeClass="active"
                    className="nav-link"
                    to="home"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    Home
                  </Link>
                  {/* <div className="dropdown-menu">
                                            <ul className="mega-menu d-lg-flex">
                                            <li className="mega-menu-col col-lg-3">
                                                    <ul> 
                                                        <li>
                                                            <a className="dropdown-item menu-link dropdown-toggler" href="#">Home - Creative</a>
                                                            <div className="dropdown-menu">
                                                                <ul> 
                                                                    <li><a className="dropdown-item nav-link nav_item" href="index.html">Creative Layout 1</a></li> 
                                                                    <li><a className="dropdown-item nav-link nav_item" href="index-2.html">Creative Layout 2</a></li>
                                                                    <li><a className="dropdown-item nav-link nav_item" href="index-3.html">Creative Layout 3</a></li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <a className="dropdown-item menu-link dropdown-toggler" href="#">Home - Landing page</a>
                                                            <div className="dropdown-menu">
                                                                <ul> 
                                                                    <li><a className="dropdown-item nav-link nav_item" href="index-14.html">Software Landing page</a></li> 
                                                                    <li><a className="dropdown-item nav-link nav_item" href="index-15.html">App Landing page</a></li>
                                                                    <li><a className="dropdown-item nav-link nav_item" href="index-24.html">ICO Landing page</a></li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <a className="dropdown-item nav-link nav_item" href="index-4.html">Home - Business</a>
                                                    </li>
                                                    <li>
                                                            <a className="dropdown-item nav-link nav_item" href="index-5.html">Home - One Page</a>
                                                    </li>
                                                    <li>
                                                            <a className="dropdown-item nav-link nav_item" href="index-6.html">Home - Portfolio</a>
                                                    </li>
                                                    <li>
                                                            <a className="dropdown-item nav-link nav_item" href="index-7.html">Home - Restaurant</a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="mega-menu-col col-lg-3">
                                                <ul>                            
                                                    <li>
                                                            <a className="dropdown-item nav-link nav_item" href="index-8.html">Home - Construction</a>
                                                    </li>
                                                    <li>
                                                            <a className="dropdown-item nav-link nav_item" href="index-9.html">Home - Medical</a>
                                                    </li>
                                                    <li>
                                                            <a className="dropdown-item nav-link nav_item" href="index-10.html">Home - Gym</a>
                                                    </li>
                                                    <li>
                                                            <a className="dropdown-item nav-link nav_item" href="index-11.html">Home - Consultancy</a>
                                                    </li>
                                                    <li>
                                                            <a className="dropdown-item nav-link nav_item" href="index-12.html">Home - Shop</a>
                                                    </li>
                                                    <li>
                                                            <a className="dropdown-item nav-link nav_item active" href="index-13.html">Home - Digital Agency</a>
                                                    </li>
                                                </ul>
                                            </li>
                                                <li className="mega-menu-col col-lg-3">
                                                <ul>
                                                    <li>
                                                            <a className="dropdown-item nav-link nav_item" href="index-16.html">Home - SEO Marketing</a>
                                                    </li>
                                                    <li>
                                                            <a className="dropdown-item nav-link nav_item" href="index-17.html">Home - Web Agency</a>
                                                    </li>
                                                    <li>
                                                            <a className="dropdown-item nav-link nav_item" href="index-18.html">Home - Agriculture</a>
                                                    </li>
                                                    <li>
                                                            <a className="dropdown-item nav-link nav_item" href="index-19.html">Home - Blog</a>
                                                    </li>
                                                    <li>
                                                            <a className="dropdown-item nav-link nav_item" href="index-20.html">Home - Wedding</a>
                                                    </li>
                                                    <li>
                                                            <a className="dropdown-item nav-link nav_item" href="index-21.html">Home - Travel</a>
                                                    </li>
                                                </ul>
                                            </li>
                                                <li className="mega-menu-col col-lg-3">
                                                <ul>
                                                    <li>
                                                            <a className="dropdown-item nav-link nav_item" href="index-22.html">Home - Yoga</a>
                                                    </li>
                                                    <li>
                                                            <a className="dropdown-item nav-link nav_item" href="index-23.html">Home - Education</a>
                                                    </li>
                                                    <li>
                                                            <a className="dropdown-item nav-link nav_item" href="index-25.html">Home - Hosting</a>
                                                    </li>
                                                    <li>
                                                            <a className="dropdown-item nav-link nav_item" href="index-26.html">Home - Event</a>
                                                    </li>
                                                    <li>
                                                            <a className="dropdown-item nav-link nav_item" href="index-27.html">Home - Hotel Booking</a>
                                                    </li>
                                                    <li>
                                                            <a className="dropdown-item nav-link nav_item" href="index-28.html">Home - Beauty Spa</a>
                                                    </li>
                                                </ul>
                                            </li>
                                            </ul>
                                        </div>    */}
                </li>

                <li>
                  <Link
                    activeClass="active"
                    className="nav-link"
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    About
                  </Link>
               
                </li>
                <li>

                <Link
                    activeClass="active"
                    className="nav-link"
                    to="services"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    Services
                  </Link>

                 

               
                  {/* <a className="nav-link" href="demo-digital-agency/about.html">
                    Services
                  </a> */}
                </li>
                {/* <li className="dropdown">
                                    <a data-toggle="dropdown" className="nav-link dropdown-toggle" href="#">Services</a>
                                    <div className="dropdown-menu">
                                        <ul> 
                                            <li><a className="dropdown-item nav-link nav_item" href="demo-digital-agency/services.html">Services</a></li> 
                                            <li><a className="dropdown-item nav-link nav_item" href="demo-digital-agency/service-single.html">Service Single</a></li>
                                        </ul>
                                    </div>   
                                </li> */}
                {/* <li><a className="nav-link" href="demo-digital-agency/pricing.html">Pricing</a></li> */}
                {/* <li className="dropdown">
                                    <a data-toggle="dropdown" className="nav-link dropdown-toggle" href="#">Blog</a>
                                    <div className="dropdown-menu">
                                        <ul> 
                                            <li><a className="dropdown-item nav-link nav_item" href="demo-digital-agency/blog.html">Blog</a></li> 
                                            <li><a className="dropdown-item nav-link nav_item" href="demo-digital-agency/blog-detail.html">Blog Detail</a></li>
                                        </ul>
                                    </div>   
                                </li> */}
                <li>
                <Link
                    activeClass="active"
                    className="nav-link"
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            {/* <ul className="navbar-nav attr-nav align-items-center">
                            <li><a href="javascript:void(0);" className="nav-link search_trigger"><i className="ion-ios-search-strong"></i></a>
                                <div className="search-overlay">
                                    <span className="close-search"><i className="ion-ios-close-empty"></i></span>
                                    <div className="search_wrap">
                                        <form>
                                            <input type="text" placeholder="Search" className="form-control" id="search_input" />
                                            <button type="submit" className="search_icon"><i className="ion-ios-search-strong"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </li>
                        </ul> */}
          </nav>
        </div>
      </header>
    </>
  );
}

export default Header;

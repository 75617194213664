import React from "react";

function Loader(){
    return(
        <>
        <div className="preloader">
            <div className="loader_grid">
            <div className="loader_box loader_box1"></div>
            <div className="loader_box loader_box2"></div>
            <div className="loader_box loader_box3"></div>
            <div className="loader_box loader_box4"></div>
            <div className="loader_box loader_box5"></div>
            <div className="loader_box loader_box6"></div>
            <div className="loader_box loader_box7"></div>
            <div className="loader_box loader_box8"></div>
            <div className="loader_box loader_box9"></div>
            </div>
        </div>
    </>
    )
}


export default Loader;
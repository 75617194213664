// array of data

const Sdata = [
    {
        id: 1,
        icon: "assets/images/icon6.png",
        title: "Digital Design",
        para: "Aim to communicate a message effectively through the use of typography, color, imagery, and layout",
    },
    {
        id: 2,
        icon: "assets/images/dev.png",
        title: "Website development",
        para: "Aim to create user-friendly, accessible, and visually appealing websites that meet your needs and goals",
    },
    {
        id: 3,
        icon: "assets/images/icon2.png",
        title: "Digital Marketing",
        para: "Our Goal is to drive more traffic, generate leads, and increase conversions through effective online campaigns",
    },
        {
            id: 4,
            icon: "assets/images/icon1.png",
            title: "SEO Optimization",
            para: "Our Goal is to increase organic traffic, helping websites reach a wider audience and achieve higher search engine rankings",
        },
       
        // {
        //     id: 5,
        //     icon: "assets/images/icon3.png",
        //     title: "Email Marketing",
        //     para: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form",
        // },
       
        {
            id: 5,
            icon: "assets/images/icon5.png",
            title: "Content Strategy",
            para: "Our Goal is to create and publish high-quality that achieves business objectives and meets the needs of the target audience",
        },
        {
            id: 6,
            icon: "assets/images/icon4.png",
            title: "Analystics",
            para: "Our Aim is to provide systematic examination of data or statistics to gain insights and make informed decisions",
        },
       
]


export default Sdata;
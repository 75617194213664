import React from "react";

function Team(props){
    return(
    <>
        <div className="col-lg-3 col-sm-6 mb-md-4 mb-3 pb-2 text-center">
            <div className="team_box team_hover_style1 social_white light_gray_bg box_shadow4 animation" data-animation="fadeInUp" data-animation-delay="0.3s">
                <div className="team_img">
                    <img src={props.icon} alt="team1"/>
                    <ul className="list_none social_icons border_social">
                        <li><a href="#">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</a></li>
                        {/* <li><a href="#"><i className="ion-social-twitter"></i></a></li>
                        <li><a href="#"><i className="ion-social-googleplus"></i></a></li>
                        <li><a href="#"><i className="ion-social-instagram-outline"></i></a></li> */}
                    </ul>
                </div>
                
                <div className="team_title">
                    <h5>{props.title}</h5>
                    <span>{props.para}</span>
                </div>
            </div>
        </div>
    </>
    )
}

export default Team;
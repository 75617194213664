import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";

function Footer() {
  return (
    <>
      {/* // START FOOTER SECTION */}
      <footer>
        <div className="top_footer light_gray_bg">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-4 col-md-12 animation"
                data-animation="fadeInUp"
                data-animation-delay="0.2s"
              >
                <div className="footer_logo">
                  <a href="index-13.html">
                    <img alt="logo" src="assets/images/logon_finalno_bg.png" />
                  </a>
                </div>
                <p>
                  If you're looking for a web development company that truly
                  cares about your success and is dedicated to delivering the
                  best possible results, look no further than Codeavoid.
                </p>

                <p> Let us bring your web vision to life.</p>
                <ul className="list_none footer_social footer_social_dark">
                  <li>
                    <a href="#">
                      <i className="ion-social-facebook"></i>
                    </a>
                  </li>
                  {/* <li><a href="#"><i className="ion-social-twitter"></i></a></li>
                              <li><a href="#"><i className="ion-social-googleplus"></i></a></li> */}
                  <li>
                    <a href="#">
                      <i className="ion-social-youtube-outline"></i>
                    </a>
                  </li>
                  {/* <li><a href="#"><i className="ion-social-instagram-outline"></i></a></li> */}
                </ul>
              </div>
              <div
                className="col-lg-2 col-6 mt-4 mt-lg-0 animation"
                data-animation="fadeInUp"
                data-animation-delay="0.3s"
              >
                <h6 className="widget_title">Services</h6>
                <ul className="list_none widget_links_style2">
                  <li>
                    <Link
                      activeClass="active"
                      to="services"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      Web Devlopment
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClass="active"
                      to="services"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      E-commerce Solution
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClass="active"
                      to="services"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      Business Solution
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link
                      activeClass="active"
                      to="services"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      Online Marketing
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClass="active"
                      to="services"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      Digital Marketing
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                className="col-lg-2 col-6 mt-4 mt-lg-0 animation"
                data-animation="fadeInUp"
                data-animation-delay="0.4s"
              >
                <h6 className="widget_title">USEFUL LINKS</h6>
                <ul className="list_none widget_links_style2">
                  <li>
                    <Link
                      activeClass="active"
                      to="about"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      About Us
                    </Link>
                  </li>
                  {/* <li><a href="#">Features</a></li>
                              <li><a href="#">Feedback</a></li> */}
                  {/* <li><a href="#">Support center</a></li> */}
                  <li>
                    <Link
                      activeClass="active"
                      to="contact"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                className="col-lg-4 col-md-12 mt-4 mt-lg-0 animation"
                data-animation="fadeInUp"
                data-animation-delay="0.5s"
              >
                <h6 className="widget_title">Subscribe Newsletter</h6>
                <p>Get started today and see results tomorrow.</p>
                <div className="newsletter_form">
                  <form>
                    <div className="rounded_input input_border2">
                      <input
                        type="text"
                        required=""
                        placeholder="Enter Email Address"
                      />
                    </div>
                    <button
                      type="submit"
                      title="Subscribe"
                      className="btn btn-default btn-radius"
                      name="submit"
                      value="Submit"
                    >
                      Subscribe
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom_footer border-top light_gray_bg">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <p className="copyright m-md-0 text-center">
                  &copy; 2023 All Rights Reserved by Codeavoid Verticals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
